import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CloudinaryModule } from '@x/common/cloudinary';
import { IconModule } from '@x/common/icon';
import { MediaModule } from '@x/common/media';
import { DashboardCoreModule } from '../core';
import { MediaDropboxComponent } from './components/media-dropbox/media-dropbox.component';
import { MediaGalleryDialogComponent } from './components/media-gallery-dialog/media-gallery-dialog.component';
import { MediaThumbnailComponent } from './components/media-thumbnail/media-thumbnail.component';
import { MediaUploadService } from './services/media-upload.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    CloudinaryModule,
    IconModule,
    MediaModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    DashboardCoreModule,
  ],
  declarations: [MediaDropboxComponent, MediaGalleryDialogComponent, MediaThumbnailComponent],
  exports: [MediaDropboxComponent, MediaGalleryDialogComponent, MediaThumbnailComponent],
  providers: [MediaUploadService],
})
export class DashboardMediaModule {}
