<input
  id="media_file_input"
  class="file-input"
  (change)="fileInputChanged($event)"
  type="file"
  #fileInput
/>

<fa-icon
  icon="cloud-upload-alt"
  size="2x"
  [class.loading]="(uploadsLoading$ | async) || false"
></fa-icon>

<span class="hint">Drop media to upload</span>

<button mat-button type="button" (click)="fileInput.click()">+ Upload</button>
<button mat-button type="button" (click)="openMediaUrlDialog()">+ URL</button>

<ng-template #mediaUrlDialog>
  <h1 mat-dialog-title>Add Media URL</h1>
  <form
    [formGroup]="mediaGroup"
    mat-dialog-content
    class="x-media-url-dialog"
    id="media_form"
    (ngSubmit)="submit()"
  >
    <div class="row">
      <mat-form-field appearance="fill" class="url-field" style="width: 400px">
        <mat-label>URL</mat-label>
        <input matInput required formControlName="mediaUrl" placeholder="https://..." />
        <mat-error>Not a valid URL</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>Media Type</mat-label>
        <mat-select formControlName="mediaType" required>
          <mat-option value="image">Image URL</mat-option>
          <mat-option value="video">Video URL</mat-option>
          <mat-option value="youtube">Youtube URL</mat-option>
          <mat-option value="vimeo">Vimeo URL</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" [mat-dialog-close]="false">Cancel</button>
    <button
      mat-button
      type="submit"
      color="primary"
      form="media_form"
      [disabled]="!mediaGroup.valid"
    >
      Add URL
    </button>
  </div>
</ng-template>
