import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getYoutubeVideoIdFromUrl } from '@x/common/utils/media';

let apiLoaded = false;

@Component({
  selector: 'x-youtube-player',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['youtube-player.component.scss'],
  template: `
    <iframe
      *ngIf="src"
      width="100%"
      height="100%"
      [src]="src"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  `,
  host: {
    class: 'x-youtube-player',
  },
})
export class YoutubePlayerComponent implements OnInit {
  @Input()
  height: number;

  @Input()
  width: number;

  @Input()
  set url(url: string | null | undefined) {
    let id = url ? getYoutubeVideoIdFromUrl(url) : null;

    if (typeof id === 'string') {
      this.videoId = id;

      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/embed/${id}`,
      );

      this.mediaLoaded.emit();
    } else {
      console.warn('YoutubePlayerComponent: not a valid YouTube url', url);
    }
  }

  @Input()
  videoId: string | null = null;

  src: SafeResourceUrl | null = null;

  @Output() mediaLoaded = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}
}
