import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaGalleryDialogComponent } from '../components/media-gallery-dialog/media-gallery-dialog.component';
import { MediaItem } from '../types/media-upload';

@Injectable({ providedIn: 'root' })
export class MediaDialogService {
  constructor(private _http: HttpClient, private matDialog: MatDialog) {}

  openGalleryDialog(medias: MediaItem[], selectedIndex: number = 0) {
    let dialog = this.matDialog.open<MediaGalleryDialogComponent>(MediaGalleryDialogComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      hasBackdrop: false,
      panelClass: 'media-gallery-dialog',
      data: { medias, selectedIndex },
    });
    return dialog;
  }
}
