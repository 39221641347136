import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonValidator } from '@x/common/form';
import { getMediaTypeFromUrl, MediaType } from '@x/common/utils/media';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MediaUploadService } from '../../services/media-upload.service';
import { MediaUploadResult } from '../../types/media-upload';

@Component({
  selector: 'x-media-dropbox',
  templateUrl: 'media-dropbox.component.html',
  styleUrls: ['media-dropbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-media-dropbox',
  },
})
export class MediaDropboxComponent implements OnInit, OnDestroy {
  @Output()
  mediaAdded = new EventEmitter<MediaUploadResult>();

  @HostBinding('class.dragging')
  isDraggingMedia = false;

  @ViewChild('mediaUrlDialog')
  mediaUrlDialogRef: TemplateRef<any>;

  mediaUrlControl = new UntypedFormControl(null, [Validators.required, CommonValidator.url()]);
  mediaTypeControl = new UntypedFormControl(MediaType.IMAGE, [Validators.required]);

  mediaGroup = new UntypedFormGroup({
    mediaUrl: this.mediaUrlControl,
    mediaType: this.mediaTypeControl,
  });

  uploads$ = this.mediaService.uploads();
  uploadsLoading$ = this.mediaService.isLoading();

  dialogRef: MatDialogRef<any>;

  private _destroy$ = new Subject<void>();

  constructor(
    private dialogs: MatDialog,
    private mediaService: MediaUploadService,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.mediaService
      .uploadsCompleted()
      .pipe(takeUntil(this._destroy$))
      .subscribe((result) => {
        this.mediaAdded.emit(result);
      });

    this.mediaUrlControl.valueChanges.pipe(debounceTime(300)).subscribe((url) => {
      if (typeof url === 'string' && url.length > 4) {
        const type = getMediaTypeFromUrl(url);
        if (type) this.mediaTypeControl.setValue(type, { emitEvent: false });
      }
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  fileInputChanged(event: Event) {
    let input = <HTMLInputElement>event.target;

    if (input.files && input.files.length > 0) {
      console.log(input.files);
      this.uploadFiles(input.files);
    }

    input.value = '';
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
    }

    if (!this.isDraggingMedia) {
      this.isDraggingMedia = true;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    if (this.isDraggingMedia) {
      this.isDraggingMedia = false;
    }

    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';

      this.uploadFiles(event.dataTransfer.files);
    }

    this.isDraggingMedia = false;

    event.preventDefault();
    event.stopPropagation();
  }

  openMediaUrlDialog() {
    this.mediaGroup.reset({ mediaUrl: null, mediaType: null }, { emitEvent: false });
    this.dialogRef = this.dialogs.open(this.mediaUrlDialogRef);
  }

  submit() {
    if (!this.mediaGroup.valid) return;

    let media: MediaUploadResult = {
      url: this.mediaUrlControl.value,
      type: this.mediaTypeControl.value,
      originalName: this.mediaUrlControl.value,
    };

    this.mediaAdded.emit(media);
    this.mediaService.postMediaResult(media);

    if (this.dialogRef) {
      this.dialogRef.close();
    }

    this.changeRef.markForCheck();
  }

  private uploadFiles(list: FileList) {
    for (let i = 0; i < list.length; i++) {
      this.mediaService.manageUpload(list[i]);
    }
  }
}
