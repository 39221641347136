import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaItem } from '../../types/media-upload';

export interface MediaGalleryDialogData {
  selectedIndex: number;
  medias: MediaItem[];
}

@Component({
  selector: 'x-media-gallery-dialog',
  templateUrl: './media-gallery-dialog.component.html',
  styleUrls: ['./media-gallery-dialog.component.scss'],
})
export class MediaGalleryDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MediaGalleryDialogData,
    public dialog: MatDialogRef<MediaGalleryDialogData>,
  ) {}

  selectedIndex: number;
  zooming: boolean = false;

  ngOnInit(): void {
    this.selectedIndex = this.data.selectedIndex;
  }

  select(index: number) {
    console.log('select(' + index + ')');
    this.selectedIndex = -1; // force unload of currently slected item
    this.selectedIndex = index;
  }
  selectPrevious() {
    console.log('selectPrevious()');
    this.select(this.selectedIndex < 1 ? this.data.medias.length - 1 : --this.selectedIndex);
  }
  selectNext() {
    console.log('selectNext()');
    this.select(this.selectedIndex >= this.data.medias.length - 1 ? 0 : ++this.selectedIndex);
  }

  toggleZoom() {
    this.zooming = !this.zooming;
  }
}
