import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MediaType,
  getMediaTypeFromUrl,
  getVimeoVideoThumbnailFromUrl,
  getYoutubeVideoThumbnailFromUrl,
} from '@x/common/utils/media';

@Component({
  selector: 'x-media-thumbnail',
  templateUrl: './media-thumbnail.component.html',
  styleUrls: ['./media-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-media-thumbnail',
  },
})
export class MediaThumbnailComponent {
  @Input()
  set type(type: string | MediaType) {
    this._type = type;
    this.updateThumbnail();
  }
  get type() {
    return this._type;
  }

  @Input()
  set url(url: string | undefined) {
    this._url = url;
    if (!this._type && url) {
      this._type = getMediaTypeFromUrl(url) ?? MediaType.IMAGE;
    }
    this.updateThumbnail();
  }
  get url() {
    return this._url;
  }

  @Input()
  set width(width: number | undefined | string) {
    this._width = coerceNumberProperty(width);
  }
  get width() {
    return this._width;
  }

  @Input()
  set height(height: number | undefined | string) {
    this._height = coerceNumberProperty(height);
  }
  get height() {
    return this._height;
  }

  _width: number | undefined = 200;
  _height: number | undefined = 200;
  _url: string | undefined;
  _type: string | MediaType = MediaType.IMAGE;

  _thumbnailUrl: string;

  @ViewChild('previewDialog')
  defaultPreviewDialog: TemplateRef<any>;

  @Input()
  previewDialog: TemplateRef<any>;

  @Input()
  @HostBinding('class.show-type-icon')
  showTypeIcon = false;

  constructor(private matDialog: MatDialog) {}

  updateThumbnail() {
    if (this._url) {
      switch (this.type) {
        case MediaType.YOUTUBE:
          this._thumbnailUrl = getYoutubeVideoThumbnailFromUrl(this._url);
          break;
        case MediaType.VIMEO:
          this._thumbnailUrl = getVimeoVideoThumbnailFromUrl(this._url);
          break;
        default:
          this._thumbnailUrl = this._url;
      }
    }
  }

  @HostListener('click')
  openPreview() {
    this.matDialog.open(this.previewDialog ?? this.defaultPreviewDialog, {
      backdropClass: 'x-media-preview-backdrop',
      panelClass: 'x-media-preview-panel',
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
}
