<div class="toolbar">
  <button mat-icon-button (click)="toggleZoom()">
    <fa-icon [icon]="zooming ? 'search-minus' : 'search-plus'"></fa-icon>
  </button>
  <button mat-icon-button (click)="dialog.close()">
    <fa-icon icon="times"></fa-icon>
  </button>
</div>
<div
  class="carousel"
  *ngIf="data.medias[selectedIndex] as selectedMedia"
  [ngClass]="{ constrain: !zooming }"
>
  <ng-container *ngIf="selectedMedia.url" [ngSwitch]="selectedMedia.type">
    <x-youtube-player
      class="item"
      *ngSwitchCase="'youtube'"
      [url]="selectedMedia.url"
    ></x-youtube-player>
    <img class="item" *ngSwitchCase="'image'" [xCloudImage]="selectedMedia.url" />
    <video
      class="item"
      *ngSwitchCase="'video'"
      [xCloudVideo]="selectedMedia.url"
      [width]="800"
      [height]="600"
    ></video>
    <x-vimeo-player
      class="item"
      *ngSwitchCase="'vimeo'"
      [url]="selectedMedia.url"
      [width]="800"
      [height]="600"
    ></x-vimeo-player>
  </ng-container>
  <button mat-icon-button (click)="selectPrevious()">
    <fa-icon icon="chevron-left"></fa-icon>
  </button>
  <button mat-icon-button (click)="selectNext()">
    <fa-icon icon="chevron-right"></fa-icon>
  </button>
</div>
<div class="thumbnails media-grid">
  <x-media-thumbnail
    *ngFor="let media of data.medias; let i = index"
    [ngClass]="{ active: selectedIndex === i }"
    [url]="media.url"
    [width]="100"
    [height]="100"
    [type]="media.type"
    (click)="select(i)"
  ></x-media-thumbnail>
</div>
