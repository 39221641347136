export type ImageFormat =
  | string
  | 'gif'
  | 'png'
  | 'jpg'
  | 'bmp'
  | 'ico'
  | 'pdf'
  | 'tiff'
  | 'eps'
  | 'jpc'
  | 'jp2'
  | 'psd'
  | 'webp'
  | 'zip'
  | 'svg'
  | 'webm'
  | 'wdp'
  | 'hpx'
  | 'djvu'
  | 'ai'
  | 'flif'
  | 'bpg'
  | 'miff'
  | 'tga'
  | 'heic';

export type VideoFormat =
  | string
  | 'auto:video'
  | 'flv'
  | 'm3u8'
  | 'ts'
  | 'mov'
  | 'mkv'
  | 'mp4'
  | 'mpd'
  | 'ogv'
  | 'webm';

export type ImageCropMode =
  | string
  | 'scale'
  | 'fit'
  | 'limit'
  | 'mfit'
  | 'fill'
  | 'lfill'
  | 'pad'
  | 'lpad'
  | 'mpad'
  | 'crop'
  | 'thumb'
  | 'imagga_crop'
  | 'imagga_scale';

export type VideoCropMode =
  | string
  | 'fill'
  | 'fill_pad'
  | 'crop'
  | 'scale'
  | 'fit'
  | 'limit'
  | 'pad'
  | 'lpad';

export type Gravity =
  | string
  | 'north_west'
  | 'north'
  | 'north_east'
  | 'west'
  | 'center'
  | 'east'
  | 'south_west'
  | 'south'
  | 'south_east'
  | 'xy_center'
  | 'face'
  | 'face:center'
  | 'face:auto'
  | 'faces'
  | 'faces:center'
  | 'faces:auto'
  | 'body'
  | 'body:face'
  | 'adv_face'
  | 'adv_faces'
  | 'adv_eyes'
  | 'custom'
  | 'custom:face'
  | 'custom:faces'
  | 'custom:adv_face'
  | 'custom:adv_faces'
  | 'auto'
  | 'auto:adv_face'
  | 'auto:adv_faces'
  | 'auto:adv_eyes'
  | 'auto:body'
  | 'auto:face'
  | 'auto:faces'
  | 'auto:custom_no_override'
  | 'auto:none'
  | 'liquid'
  | 'ocr_text';

export interface CommonTransformationOptions {
  crop?: ImageCropMode;
  width?: number | string;
  height?: number | string;
  size?: string;
  aspect_ratio?: number | string;
  gravity?: Gravity;
  x?: number | string;
  y?: number | string;
  zoom?: number | string;
  effect?: string | Array<number | string>;
  background?: string;
  radius?: number | string;
  dpr?: number | string;
  quality?: number | string;
  start_offset?: number | 'auto';

  [key: string]: any;
}

export interface ImageTransformationOptions extends CommonTransformationOptions {
  underlay?: string;
  color?: string;
  opacity?: number | string;
  format?: ImageFormat | VideoFormat;
}

export interface VideoTransformationOptions extends CommonTransformationOptions {
  fps?: string | Array<number | string>;
  duration?: number | string;
  format?: VideoFormat;
}

export type TransformationOptions = ImageTransformationOptions | VideoTransformationOptions;

const CLOUDINARY_URL_REGEX =
  /^(?:http|https):\/\/res\.cloudinary\.com\/(?:(?<cloud_name>[a-z0-9\-]+)\/)(?:(?<type>image|video)\/)(?:(?<mode>upload|fetch)\/)(?:(?<transform>(?:[^_/]+_[^,/]+,?)*)\/)?(?:(?<version>v[0-9]+)\/)?(?<public_id>[^\s^\.]+)(?:\.(?<ext>.+))?$/i;

const transformMap: any = {
  crop: 'c_',
  width: 'w_',
  height: 'h_',
  aspect_ratio: 'ar_',
  gravity: 'g_',
  x: 'x_',
  y: 'y_',
  zoom: 'z_',
  effect: 'e_',
  background: 'b_',
  radius: 'r_',
  dpr: 'dpr_',
  quality: 'q_',
  start_offset: 'so_',
  // image only
  underlay: 'u_',
  color: 'co_',
  opacity: 'o_',
  format: 'f_',
  // video only
  fps: 'fps_',
  duration: 'du_',
};

export interface CloudinaryUrl {
  full?: string;
  cloud_name?: string;
  type?: 'image' | 'video';
  mode?: 'upload' | 'fetch';
  transform?: string;
  public_id?: string;
  extension?: string;
  version?: string;
}

/**
 *
 * Example url: https://res.cloudinary.com/babylonstoren-dev/image/upload/v1618489400/shop/image/image-0b7dd6f1dfd854ab790fccc84f5f-c15583aa5ba4.png
 * With existing transform: https://res.cloudinary.com/babylonstoren-dev/image/upload/c_scale,h_420/v1618487773/shop/image/image-bluegum-honey-medium-029da8d71464.png
 *
 * @param url Url to check
 */
export function isCloudinaryUrl(url: string): boolean {
  return CLOUDINARY_URL_REGEX.test(url);
}

export function deconstructCloudinaryUrl(url: string): CloudinaryUrl | null {
  let matches = CLOUDINARY_URL_REGEX.exec(url);

  if (!matches) {
    return null;
  }

  return {
    full: matches[0],
    cloud_name: matches.groups?.cloud_name,
    type: <'image' | 'video'>matches.groups?.type,
    mode: <'upload' | 'fetch'>matches.groups?.mode,
    transform: matches.groups?.transform,
    public_id: matches.groups?.public_id,
    extension: matches.groups?.extension,
    version: matches.groups?.extension,
  };
}

export function constructCloudinaryUrl(obj: CloudinaryUrl) {
  return (
    `https://res.cloudinary.com/${obj.cloud_name}/` +
    `${obj.type}/${obj.mode}/` +
    (obj.version ? `${obj.version}/` : '') +
    (obj.transform ? `${obj.transform}/` : '') +
    obj.public_id +
    (obj.extension ? `.${obj.extension}` : '')
  );
}

export function transformPathFromOptions(options: TransformationOptions): string {
  let transforms: string[] = Object.keys(options)
    .filter((key) => transformMap[key] !== undefined && options[key])
    .map((key) => {
      return `${transformMap[key]}${options[key]}`;
    });

  return transforms.join(',');
}

export function transformImageUrl(url: string, options: ImageTransformationOptions) {
  const parsed = deconstructCloudinaryUrl(url);
  const transform = transformPathFromOptions(options);

  if (!parsed) {
    console.warn("transformImageUrl: not a Cloudinary url '%s'", url);
    return null;
  }

  // replace the url transform
  parsed.transform = transform;
  return constructCloudinaryUrl(parsed);
}
