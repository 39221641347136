import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CloudImageDirective } from './cloud-image.directive';
import { CloudVideoDirective } from './cloud-video.directive';
import { CloudinaryModuleConfig, CLOUDINARY_MODULE_CONFIG } from './cloudinary.config';

@NgModule({
  imports: [CommonModule],
  declarations: [CloudImageDirective, CloudVideoDirective],
  exports: [CloudImageDirective, CloudVideoDirective],
})
export class CloudinaryModule {
  static forRoot(config: CloudinaryModuleConfig): ModuleWithProviders<CloudinaryModule> {
    return {
      ngModule: CloudinaryModule,
      providers: [{ provide: CLOUDINARY_MODULE_CONFIG, useValue: config }],
    };
  }
}
