import { Directive, HostBinding, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  CloudinaryUrl,
  constructCloudinaryUrl,
  deconstructCloudinaryUrl,
  Gravity,
  isCloudinaryUrl,
  transformPathFromOptions,
  VideoCropMode,
  VideoTransformationOptions,
} from './cloudinary';
import { CloudinaryModuleConfig, CLOUDINARY_MODULE_CONFIG } from './cloudinary.config';

@Directive({
  selector: '[xCloudVideo]',
})
export class CloudVideoDirective implements OnChanges {
  @Input('xCloudVideo')
  set url(url: string | undefined) {
    this._url = url;
    if (url) {
      if (isCloudinaryUrl(url)) {
        this._parsed = deconstructCloudinaryUrl(url);
      } else {
        this._parsed = {
          cloud_name: this.config.cloudName,
          type: 'video',
          mode: 'fetch',
          public_id: url,
        };
      }
    }
  }
  get url() {
    return this._url;
  }

  @Input()
  crop?: VideoCropMode;

  @Input()
  width?: number | string;

  @Input()
  height?: number | string;

  @Input()
  size?: string;

  @Input()
  format?: string = 'auto:video';

  @Input()
  aspectRatio?: number | string;

  @Input()
  gravity?: Gravity;

  @Input()
  x?: number | string;

  @Input()
  y?: number | string;

  @Input()
  zoom?: number | string;

  @Input()
  effect?: string | Array<number | string>;

  @Input()
  background?: string;

  @Input()
  radius?: number | string;

  @Input()
  dpr?: number | string;

  @Input()
  quality?: number | string;

  @Input()
  options?: VideoTransformationOptions;

  @HostBinding('src')
  src?: string;

  _parsed?: CloudinaryUrl | null;
  _url?: string;

  constructor(
    @Inject(CLOUDINARY_MODULE_CONFIG)
    readonly config: CloudinaryModuleConfig,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this._parsed) {
      this.src = undefined;
    }

    const transform: VideoTransformationOptions = {
      crop: this.crop,
      width: this.width,
      height: this.height,
      size: this.size,
      aspect_ratio: this.aspectRatio,
      gravity: this.gravity,
      x: this.x,
      y: this.y,
      zoom: this.zoom,
      effect: this.effect,
      background: this.background,
      radius: this.radius,
      dpr: this.dpr,
      quality: this.quality,
      format: this.format,
      ...this.options,
    };

    this.src = constructCloudinaryUrl({
      ...this._parsed,
      transform: transformPathFromOptions(transform),
    });
  }
}
