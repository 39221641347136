import { MediaType } from '@x/common/utils/media';

export interface MediaUploadResult {
  url: string;
  width?: number;
  height?: number;
  type: MediaType;
  quality?: number | null;
  originalName?: string | null;
  position?: number | null;
}

export enum MediaUploadStatus {
  LOADING = 'loading',
  ERROR = 'error',
  OK = 'ok',
}

export interface MediaItem {
  url: string;
  type: string | MediaType;
}

export class MediaUploadLoadingState {
  status = MediaUploadStatus.LOADING;
  constructor(public filename: string) {}
}
export class MediaUploadCompletedState {
  status = MediaUploadStatus.OK;
  constructor(public filename: string, public result: MediaUploadResult) {}
}
export class MediaUploadErrorState {
  status = MediaUploadStatus.ERROR;
  constructor(public filename: string, public error: any) {}
}

export type MediaUploadState =
  | MediaUploadLoadingState
  | MediaUploadCompletedState
  | MediaUploadErrorState;
