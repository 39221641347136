import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getVimeoVideoIdFromUrl } from '@x/common/utils/media';

@Component({
  selector: 'x-vimeo-player',
  template: `
    <iframe
      *ngIf="src"
      [src]="src"
      [width]="width ?? '100%'"
      [height]="height ?? '100%'"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  `,
  styleUrls: ['./vimeo-player.component.scss'],
  host: {
    class: 'x-vimeo-player',
  },
})
export class VimeoPlayerComponent implements OnInit {
  @Input()
  set width(width: number | null | undefined) {
    this._width = coerceNumberProperty(width);
  }
  get width() {
    return this._width;
  }

  @Input()
  set height(height: number | null | undefined) {
    this._height = coerceNumberProperty(height);
  }
  get height() {
    return this._height;
  }

  @Input()
  set url(url: string | undefined) {
    if (url) {
      let id = getVimeoVideoIdFromUrl(url);
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://player.vimeo.com/video/${id}`,
      );

      this.mediaLoaded.emit();
    }
  }

  src?: SafeResourceUrl | null = null;
  _height: number | null = null;
  _width: number | null = null;

  @Output() mediaLoaded = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
