import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IMAGE_EXTENTIONS,
  MediaValidation,
  VIDEO_EXTENTIONS,
  makeId,
  validateFile,
} from '@x/common/utils';
import { FileUploadSevice } from '@x/dashboard/form';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import {
  MediaUploadCompletedState,
  MediaUploadErrorState,
  MediaUploadLoadingState,
  MediaUploadResult,
  MediaUploadState,
  MediaUploadStatus,
} from '../types/media-upload';

const IMAGE_MAX_SIZE = 20000000;
const VIDEO_MAX_SIZE = 100000000;

export const DEFAULT_MEDIA_VALIDATION = {
  imageFormats: IMAGE_EXTENTIONS,
  imageMaxSize: IMAGE_MAX_SIZE,
  videoFormats: VIDEO_EXTENTIONS,
  videoMaxSize: VIDEO_MAX_SIZE,
  videoAllowed: true,
};

@Injectable({ providedIn: 'root' })
export class MediaUploadService {
  private uploadsChanged$ = new Subject<void>();
  private uploadsCompleted$ = new Subject<MediaUploadResult>();
  private uploadsMap: { [key: string]: MediaUploadState } = {};

  constructor(private readonly fileUploadService: FileUploadSevice) {}

  uploads() {
    return this.uploadsChanged$.pipe(
      startWith(),
      map(() => {
        return Object.values(this.uploadsMap);
      }),
    );
  }

  isLoading(): Observable<boolean> {
    return this.uploads().pipe(
      map((uploads) => {
        for (const state of uploads) {
          if (state.status === MediaUploadStatus.LOADING) return true;
        }
        return false;
      }),
    );
  }

  uploadsCompleted() {
    return this.uploadsCompleted$.asObservable();
  }

  clearCompleted() {
    Object.keys(this.uploadsMap).forEach((key) => {
      if (this.uploadsMap[key].status === MediaUploadStatus.OK) {
        delete this.uploadsMap[key];
      }
    });
    this.uploadsChanged$.next();
  }

  upload(file: File): Observable<HttpEvent<MediaUploadResult>> {
    return this.fileUploadService.createUpload<MediaUploadResult>(
      `/ecommerce/api/media/upload`,
      file,
    );
  }

  observeUpload(file: File): Observable<MediaUploadState> {
    let filename = file.name;
    let loadingState = new MediaUploadLoadingState(filename);

    return this.upload(file).pipe(
      map((response) => {
        if (response instanceof HttpResponse && response.body) {
          return new MediaUploadCompletedState(filename, response.body);
        }
        return loadingState;
      }),
      catchError((e) => {
        return throwError(new MediaUploadErrorState(filename, e));
      }),
    );
  }

  manageUpload(file: File, validation: MediaValidation = DEFAULT_MEDIA_VALIDATION): string {
    let ref = makeId();
    let errors = validateFile(file, validation);

    if (errors.length) {
      this.uploadsMap[ref] = new MediaUploadErrorState(file.name, errors.join(', '));
      this.uploadsChanged$.next();
      return ref;
    }

    this.observeUpload(file).subscribe(
      (state) => {
        this.uploadsMap[ref] = state;
        this.uploadsChanged$.next();
        if (state instanceof MediaUploadCompletedState) {
          this.uploadsCompleted$.next(state.result);
        }
      },
      (e) => {
        if (e instanceof MediaUploadErrorState) {
          this.uploadsMap[ref] = e;
        } else {
          this.uploadsMap[ref] = new MediaUploadErrorState(file.name, e);
        }
        this.uploadsChanged$.next();
      },
    );

    return ref;
  }

  postMediaResult(result: MediaUploadResult) {
    this.uploadsCompleted$.next(result);
  }
}
