<ng-container *ngIf="_thumbnailUrl" [ngSwitch]="type">
  <img
    *ngSwitchCase="'youtube'"
    [src]="_thumbnailUrl"
    [width]="_width"
    [height]="_height"
    crop="thumb"
    draggable="false"
    class="thumb"
  />
  <fa-icon
    class="type-icon"
    *ngSwitchCase="'youtube'"
    icon="video"
    matTooltip="YouTube Video"
  ></fa-icon>
  <img
    *ngSwitchCase="'vimeo'"
    [src]="_thumbnailUrl"
    [width]="_width"
    [height]="_height"
    crop="thumb"
    draggable="false"
    class="thumb"
  />
  <fa-icon
    class="type-icon"
    *ngSwitchCase="'vimeo'"
    icon="video"
    matTooltip="Vimeo Video"
  ></fa-icon>
  <img
    *ngSwitchCase="'image'"
    [xCloudImage]="_thumbnailUrl"
    [width]="_width"
    [height]="_height"
    crop="thumb"
    draggable="false"
    class="thumb"
    format="webp"
  />
  <fa-icon
    class="type-icon"
    *ngSwitchCase="'image'"
    icon="image-polaroid"
    matTooltip="Image"
  ></fa-icon>
  <img
    *ngSwitchCase="'video'"
    [xCloudImage]="_thumbnailUrl"
    [width]="_width"
    [height]="_height"
    crop="thumb"
    class="thumb"
    format="jpg"
    [startOffset]="0"
  />
  <fa-icon class="type-icon" *ngSwitchCase="'video'" icon="video" matTooltip="Video"></fa-icon>
  <fa-icon class="type-icon" *ngSwitchDefault icon="question-circle" [matTooltip]="_type"></fa-icon>
</ng-container>
<ng-template #previewDialog>
  <button mat-fab class="close-button" mat-dialog-close>
    <fa-icon icon="x"></fa-icon>
  </button>
  <div class="media-preview" [ngSwitch]="type">
    <x-vimeo-player [url]="_url" *ngSwitchCase="'vimeo'"></x-vimeo-player>
    <x-youtube-player [url]="_url" *ngSwitchCase="'youtube'"></x-youtube-player>
    <img *ngSwitchCase="'image'" [xCloudImage]="_url" draggable="false" />
    <video *ngSwitchCase="'video'" [xCloudVideo]="_url" controls autoplay></video>
  </div>
  <ng-content></ng-content>
</ng-template>
